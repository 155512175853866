.status_summary_container {
  display: -webkit-inline-box;
  row-gap: 15px;
  column-gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  .card_wrapper {
    flex-basis: 370px;
    border-radius: 10px;
  }
}

.card_div {
  list-style-type: disc;
  margin-bottom: 10px;
}

.card_header {
  background-color: #193c6d;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 8px;
  margin-bottom: 10px;
  width: 100vw;
  margin-left: -10px;
  margin-right: -8px;
  margin-top: -10px;
}

.modify-summary {
  font-family: sans-serif;
  font-size: 13px;
}

.heading {
  display: flex;
}
